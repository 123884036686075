.home {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 7px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 800px;
  object-fit: contain;
  background-size: cover; /* Ensures the image covers the full width */
  background-position: bottom center; /* Crops the top part of the image */
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
  margin-left: 20px;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(197, 192, 185);
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 2rem;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.content h3 {
  color:white;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding: 20px;
  border-radius: 8px;
  max-width: 100%;
  margin: 0 auto;
 
}

@media (max-width: 1200px) {
  .content h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 992px) {
  .content h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .content h3 {
    font-size: 1.4rem;
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .content h3 {
    font-size: 1.2rem;
    padding: 10px;
  }
}
