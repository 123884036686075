.about {
  padding: 0;
  margin: 0;
  background-color: rgb(188, 210, 214);
  width: 100%;
  box-sizing: border-box;
  padding-top: 0px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin: 0 auto; /* Center content */
  padding: 0;
  box-sizing: border-box;
  font-size: larger;
}

.image {
  flex: 1;
  max-width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.image img {
  width: 100%;
  height: auto;
}

.content {
  flex: 1;
  max-width: 100%;
  padding: 20px;
  margin: 0;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.content h1,
.content h2,
.content h4,
.content h5 {
  font-size: 2.2rem;
  color: blue;
  margin: 0px;
}

.content h3 {
  margin-left: 50px;
  color: blue;
  font-weight: 800;
  font-size: 200px;
  margin-left: -500px;
}

.content p {
  font-size: 12px;
  color: black;
  line-height: 1.6;
  margin: 10px 0;
  margin-left: -600px;
}

/* Media Queries */

/* For large screens (desktops) */
@media (min-width: 1200px) {
  .row {
    margin-left: 0; /* Adjust margin for large screens */
  }

  .content h3,
  .content h1,
  .content h2,
  .content h4,
  .content h5 {
    font-size: 1.5rem; /* Larger font size */
  }

  .content p {
    font-size: 16px; /* Larger font size for paragraphs */
  }
}

/* For tablets and small screens */
@media (max-width: 992px) {
  .row {
    flex-direction: column; /* Stack the image and content vertically */
    align-items: center;
    justify-content: center;
    margin-left: 0; /* Remove left margin for smaller screens */
  }

  .image, .content {
    max-width: 100%;
    padding: 10px;
  }

  .content h3,
  .content h1,
  .content h2,
  .content h4,
  .content h5 {
    font-size: 1.1rem;
    margin-left: auto;
  }

  .content p {
    font-size: 14px;
    margin-left: auto;
  }
}

/* For mobile phones */
@media (max-width: 576px) {
  .content h3,
  .content h1,
  .content h2,
  .content h4,
  .content h5 {
    font-size: 1rem;
    margin-left: auto;
    font-size: 30px;
  }

  .content p {
    font-size: 12px;
    margin-left: auto;
    font-size: 20px;

  }

  /* Center the content on mobile */
  .row {
    margin: 0 auto;
  }

  .image, .content {
    padding: 5px;
  }
}
