/* Global CSS variables */
:root {
  --primary: #3498db;
  --secondary: #2ecc71;
  --black: #333;
  --white: #fff;
  --gray: #666;
  --light-gray: #f5f5f5;
  --box-shadow: 0 5px 15px rgba(0,0,0,.1);
}

/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  background: var(--light-gray);
  color: var(--black);
  line-height: 1.7;
  font-size: 1.6rem;
  overflow-x: hidden;
}

section {
  padding: 5rem 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--black);
  margin-bottom: 1.5rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.btn {
  display: inline-block;
  background: var(--primary);
  color: var(--white);
  padding: 1rem 2.5rem;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  transition: background 0.3s;
}

.btn:hover {
  background: var(--secondary);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
