/* Rooms.css */

.room {
    padding: 20px;
  }
  
  .heading {
    text-align: center;
    margin-top: 2px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .room-slider {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .room-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
  }
  
  .room-image {
    position: relative;
  }
  
  .room-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .price {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .room-content {
    padding: 15px;
    text-align: center;
  }
  
  .room-content h3 {
    margin-bottom: 10px;
  }
  
  .room-content p {
    margin-bottom: 15px;
    color: #666;
  }
  
  .stars i {
    color: #FFD700;
    
  }
  
  .btn {
    background-color: #ff5a5f;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-left: 20px;
    margin-bottom: 50px;
  }
  
  .btn:hover {
    background-color: #4b89e1;
  }
  
  /* Media Queries */
  
  @media (max-width: 768px) {
    .room-slider {
      flex-direction: column;
      align-items: center;
    }
  
    .room-card {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 1.5rem;
    }
  
    .room-content h3 {
      font-size: 1.2rem;
    }
  
    .room-content p {
      font-size: 0.9rem;
    }
  
    .btn {
      padding: 8px 16px;
    }
  }
  