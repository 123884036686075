/* src/ContactForm.css */
.contact-container {
    width: 500px;
    height: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* Ensuring no background color */
    text-align: center;
}

.contact-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.contact-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

