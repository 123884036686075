.location-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.responsive-iframe {
  width: 100%;
  max-width: 600px; /* Optional max width for larger screens */
  height: 0;
  padding-bottom: 56.25%; /* Aspect ratio 16:9 */
  border: none;
  position: relative;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

  
