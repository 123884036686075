/* Container for the whole review section */
.review {
  max-width: 100%; /* Set to full width */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Align children vertically */
  gap: 20px; /* Space between heading and review cards */
}

/* Heading styles */
.heading {
  font-size: 24px; /* Adjust heading size */
  color: #333;
  text-align: center; /* Center align the heading */
}

/* Review cards container */
.review-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute cards evenly */
  gap: 20px; /* Space between cards */
}

/* Individual review card */
.review-card {
  flex: 1 1 calc(33% - 40px); /* Set the cards to take up 33% minus the gap */
  padding: 10px;
  background-color: #5affc8;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 200px; /* Ensures a minimum width for smaller screens */
}

/* Review text styles */
.review-card p {
  font-size: 16px; /* Adjusted font size */
  color: #333;
  line-height: 1.6;
  margin-left: 40px; /* Adjusted margin */
}

/* Icon styles */
.review-card i {
  color: #ffcc00;
  font-size: 24px; /* Adjusted icon size */
  margin-bottom: 10px;
}

/* User section styles */
.user {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
}

/* User image styles */
.user img {
  width: 40px; /* Adjusted image size */
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* User info styles */
.user-info h3 {
  font-size: 16px; /* Adjusted font size */
  color: #333;
}

/* Stars styles */
.stars {
  display: flex;
  gap: 5px;
  color: #ffcc00;
  font-size: 16px; /* Adjusted star size */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .review-card {
    flex: 1 1 100%; /* Stack cards vertically on smaller screens */
    min-width: auto;
  }
}
